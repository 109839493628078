@tailwind base;
@tailwind components;
@tailwind utilities;

@Layer base {
  :root {
    --primary-color: 10, 100, 255;
    --theme-color: 255, 115, 179; /* Default theme color */
  }
  .test {
    border: 1px solid red !important;
  }
  

  button:disabled {
    @apply !cursor-not-allowed;
  }

  
  h1 {
    @apply font-bold text-2xl;
  }
  h2 {
    @apply !font-bold !text-xl;
  }
  h3 {
    @apply !font-bold !text-lg;
  }
  h4 {
    @apply !font-bold !text-base px-0;
  }
  h5 {
    @apply !font-bold !text-sm px-0 ;
  }
  h6 {
    @apply !font-bold !text-xs px-0 ;
  }


  .btn1 {
    @apply !bg-primary !text-secondary !px-4 !py-2 rounded-sm cursor-pointer;
  }
  .btn1.small {
    @apply !px-2 !py-[5px] !text-[10px];
  }
  .btn1:disabled {
    @apply !cursor-not-allowed !bg-gray-500 ;
  }
  .btn1_outlined {
    @apply !border !border-primary !text-primary !px-4 !py-2 rounded-sm cursor-pointer;
  }
  .btn2 {
    @apply !bg-theme1 !text-secondary !px-4 !py-2 rounded-lg cursor-pointer flex gap-2 items-center justify-center;
  }
  .btn2.small {
    @apply !px-2 !py-[5px] !text-[10px];
  }
  .btn2:disabled {
    @apply !cursor-not-allowed !bg-gray-300 !text-gray-700;
  }
  .btn_outlined {
    @apply border border-theme1 text-theme1 p-[6px] px-3 rounded-xl hover:bg-theme1 hover:text-secondary transition-colors !important;
  }
  .btn_close {
    @apply w-5 h-5 m-2 cursor-pointer select-none rounded-full bg-primary text-white absolute right-0 top-0 flex justify-center text-xs font-bold items-center;
  }


  .circle {
    @apply flex justify-center items-center rounded-full text-[80%];
  }


  .div_mid .div_targ::after {
    content: "";
    @apply !h-1/2 !absolute right-0 !border border-primary/60;
  }

  .pag .active {
    @apply text-secondary bg-theme1 rounded-xl flex items-center justify-center !important;
  }


  .sml {
    @apply !text-xs ;
  }

  .stats_min {
    @apply !border border-primary rounded-md p-3 min-w-[200px];
  }

  .stroke-2 path {
    stroke-width: 5px;
  }


  .tabs {
    @apply my-2 relative;
  }
  .tabs > button {
    @apply !p-2 !px-4 !border !border-primary/50 bg-secondary text-primary text-xs;
  }
  .tabs > button.active {
    @apply !bg-primary !text-secondary relative;
  }
  .tabs > button.active::after {
    content: "";
    @apply !border-8 !border-transparent !border-t-primary inline-block  absolute top-full left-[calc(50%-5px)];
  }
}