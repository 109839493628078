@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

* {
  font-family: 'Poppins';
}
body {
  margin: 0;
  height: 100vh;
  font-size: 14px;
  font-family: 'Poppins';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  outline: none;
}

.btn {
  @apply p-2 px-3 flex items-center rounded-md text-xs;
}
.btn_err {
  @apply bg-red-500;
}
.btn_nofocus {
  @apply bg-primary/20;
}

.test {
  border: 1px solid red;
}

input[type='button'] {
  @apply border border-[#aaa] rounded-md px-4 py-1 font-[14px];
}
button {
  @apply font-[14px] cursor-pointer;
}


.h-0-animate {
  @apply !max-h-0 !py-0 overflow-hidden;
  transition: all .5s;
}
.h-full-animate {
  transition: all .5s;
}

p {
  @apply text-[#112211];
}


.mr {
  border: 1px solid #aaa !important;
  @apply border border-black self-center min-w-[20px] inline-block;
}

.vr {
  border: 1px solid #aaa !important;
  @apply border border-[#aaa] self-center min-h-[20px] inline-block;
}


select::placeholder {
  color: red;
}


.sqr1 {
  @apply !w-1 !h-1;
}
.sqr2 {
  @apply !w-2 !h-2;
}
.sqr3 {
  @apply !w-3 !h-3;
}
.sqr4 {
  @apply !w-4 !h-4;
}
.sqr5 {
  @apply !w-5 !h-5;
}
.sqr6 {
  @apply !w-6 !h-6;
}
.sqr8 {
  @apply !w-8 !h-8;
}
.sqr10 {
  @apply !w-10 !h-10;
}





/* Animations */

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to { 
      transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
  }
  to { 
      -webkit-transform: rotate(360deg);
  }
}

.load {
width: 14px;
height: 14px;
/* margin: 110px auto 0; */
border-color: inherit;
border-width: 3px;
border-radius: 50%;
border-right-color: transparent;
border-bottom-color: transparent;
 -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name:             rotate; 
  -webkit-animation-duration:         1.0s; 
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;
    
     transition: all 0.5s ease-in;
  animation-name:             rotate; 
  animation-duration:         1.0s; 
  animation-iteration-count:  infinite;
  animation-timing-function: linear; 
}